import React, { Component } from "react";

class Publication extends Component {
  render() {
    return (
      <div className="item-main">
        <h1>Welcome. This is the Publication page</h1>
      </div>
    );
  }
}

export default Publication;
