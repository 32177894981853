import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import Loading from './../components/Auth/Loading'
import { connect } from 'react-redux'
import { clearErrors } from '../flux/actions/errorActions'
import PropTypes from 'prop-types'

const PrivateRoute = (props) => {
  if (props.isLoading) {
    return <Loading />
  }

  if (props.token != null) {
    return <Outlet {...props} />
  } else {
    return <Navigate to='/login' />
  }
}
PrivateRoute.propTypes = {
  token: PropTypes.string,
  error: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  clearErrors: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool

}

const mapStateToProps = state => ({
  token: state.auth.token,
  error: state.error,
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(
  mapStateToProps,
  { clearErrors }
)(PrivateRoute)
