import React, { Component } from 'react'
import { getProjectById } from '../../api/project'
import '../../stylesheets/style.css'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

const WrappedComponent = props => {
  const params = useParams()

  return <Projects params={params} {...props} />
}

class Projects extends Component {
  constructor (props) {
    super(props)
    this.state = { project: null }
    this.getProjectdetails = this.getProjectdetails.bind(this)
  }

  componentDidMount () {
    console.log(this.props)
    this._isMounted = true
    this.getProjectdetails(this.props.params.ProjectId)
  }

  componentWillUnmount () {
    console.log(this.props)
    this._isMounted = false
  }

  getProjectdetails (id) {
    getProjectById(id).then((result) => {
      console.log(result)
      if (this._isMounted) {
        this.setState({ project: result.FoundedProjectId })
      }
    })
  }

  render () {
    return (
      <div className='projectInformationGrid'>
        <ProjectGeneralInformation data={this.state} />
        <ProjectMemberInformation data={this.state} />
        <ProjectAbout data={this.state} />
        <ProjectStats data={this.state} />
        <ProjectTask data={this.state} />
        <ProjectDeadline data={this.state} />
      </div>
    )
  }
}

Projects.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  projectid: PropTypes.object
}

class ProjectGeneralInformation extends Component {
  render () {
    let projectTitle = <p>Project Title: </p>
    let projectAcronym = <p>Project Acronym: </p>
    let projectStatus = <p>Project Status: </p>
    if (this.props.data.project) {
      projectTitle = (
        <p>Project Title: {this.props.data.project.projectTitle}</p>
      )
      projectAcronym = (
        <p>Project Acronym:{this.props.data.project.projectAcronym} </p>
      )
      projectStatus = (
        <p>Project Status: {this.props.data.project.projectStatus} </p>
      )
    }
    return (
      <div className='projectOverviewWidget item-projectGeneralInformationWidget'>
        <h2>General Project Information</h2>
        <div className='projectTitle'>{projectTitle}</div>
        <div className='projectAcronym'>{projectAcronym}</div>
        <div className='projectStatus'>{projectStatus}</div>
      </div>
    )
  }
}

ProjectGeneralInformation.propTypes = {
  data: PropTypes.object,
  project: PropTypes.object,
  projectAcronym: PropTypes.object
}

class ProjectMemberInformation extends Component {
  render () {
    let projectMembers = <p>Project Members: </p>
    if (this.props.data.project) {
      projectMembers = (
        <p>Project Members: {this.props.data.project.projectMembers}</p>
      )
    }
    return (
      <div className='projectOverviewWidget item-projectMemberInformationWidget'>
        <div className='projectMembers'>
          <h2>Project Members</h2>
          {projectMembers}
        </div>
      </div>
    )
  }
}
ProjectMemberInformation.propTypes = {
  data: PropTypes.object,
  project: PropTypes.object,
  projectMembers: PropTypes.object
}

class ProjectAbout extends Component {
  render () {
    let PI = <p>PI: </p>
    if (this.props.data.project) {
      PI = (
        <p>PI: {this.props.data.project.PI}</p>
      )
    }
    let About = <p>About: </p>
    if (this.props.data.project) {
      About = (
        <p>About: {this.props.data.project.About}</p>
      )
    }
    return (
      <div className='projectOverviewWidget item-projectAbout'>
        <div className='projectInvestigator'>
          <h2>PI</h2>
          {PI}
        </div>
        <div className='projectSummary'>
          <h2>About</h2>
          {About}
        </div>
      </div>
    )
  }
}
ProjectAbout.propTypes = {
  data: PropTypes.object,
  project: PropTypes.object,
  PI: PropTypes.string,
  About: PropTypes.string
}
class ProjectStats extends Component {
  render () {
    let ProjectStats = <p>ProjectStats: </p>
    if (this.props.data.project) {
      ProjectStats = (
        <p>ProjectStats: {this.props.data.project.PI}</p>
      )
    }
    return (
      <div className='projectOverviewWidget item-projectProjectStats'>
        <h2>ProjectStats</h2>
        {ProjectStats}
      </div>
    )
  }
}
ProjectStats.propTypes = {
  data: PropTypes.object,
  project: PropTypes.object,
  ProjectStats: PropTypes.string
}
class ProjectTask extends Component {
  render () {
    let ProjectTask = <p>ProjectTask: </p>
    if (this.props.data.project) {
      ProjectTask = (
        <p>ProjectTasks: {this.props.data.project.PI}</p>
      )
    }
    return (
      <div className='projectOverviewWidget item-projectProjectTask'>
        <h2>ProjectTask</h2>
        {ProjectTask}
      </div>
    )
  }
}
ProjectTask.propTypes = {
  data: PropTypes.object,
  project: PropTypes.object,
  ProjectStats: PropTypes.string
}
class ProjectDeadline extends Component {
  render () {
    let ProjectDeadline = <p>ProjectDeadline: </p>
    if (this.props.data.project) {
      ProjectDeadline = (
        <p>ProjectDeadline: {this.props.data.project.PI}</p>
      )
    }
    return (
      <div className='projectOverviewWidget item-projectProjectDeadline'>
        <h2>ProjectDeadline</h2>
        {ProjectDeadline}
      </div>
    )
  }
}
ProjectDeadline.propTypes = {
  data: PropTypes.object,
  project: PropTypes.object,
  ProjectStats: PropTypes.string
}
export default WrappedComponent
