import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const setCSSVariables = (side) => {
  try {
    document
      .getElementsByClassName('container')[0]
      .setAttribute('data-side', side)
  } catch (e) {
    console.log(e)
  }
}

const SideSelectorContext = React.createContext()

export const SideSelectorProvider = ({ children }) => {
  const [side, setSide] = useState('out' || 'in')

  const toggleSide = () => {
    if (side === 'in') {
      setSide('out')
    } else {
      setSide('in')
    }
  }
  useEffect(() => {
    setCSSVariables(side)
  })
  return (
    <SideSelectorContext.Provider value={{ toggleSide }}>
      {children}
    </SideSelectorContext.Provider>
  )
}

SideSelectorProvider.propTypes = { children: PropTypes.object }

export const SideSelectorConsumer = SideSelectorContext.Consumer

export default SideSelectorContext
