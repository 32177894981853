import React, { useState, useEffect, useRef } from 'react'
import '../../stylesheets/style.css'
import PropTypes from 'prop-types'
import UserSettingMenuDropDown from './UserSettingsMenu'
import { getUserName } from '../../api/usersettings'
import { useSelector } from 'react-redux'

const UserButton = () => {
  const [open, setOpen] = useState(false)
  const [initials, setInitials] = useState(null)
  const buttonRef = useRef()
  const token = useSelector((state) => state.auth.token)

  const handleClickInside = () => {
    setOpen(!open)
  }

  useEffect(() => {
    let isMounted = true
    const handleClickOutside = (e) => {
      if (buttonRef.current !== null && !buttonRef.current.contains(e.target) && open) {
        setOpen(false)
      }
    }
    const getInitials = (token) => {
      getUserName(token).then((result) => {
        if (isMounted) {
          setInitials(result.firstName.charAt(0) + result.lastName.charAt(0))
        }
      })
    }
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    if (token) {
      getInitials(token)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      isMounted = false
    }
  }, [open, token, buttonRef])

  return (
    <div className='UserButtonPlaceholder'>
      <div ref={buttonRef}>
        <button className='UserButton' onClick={handleClickInside}>
          {initials}
        </button>
        {open && (
          <UserSettingMenuDropDown />
        )}
      </div>
    </div>
  )
}

UserButton.propTypes = {
  children: PropTypes.object
}

export default UserButton
