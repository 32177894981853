import React, { Component } from 'react'
import TopBar from './TopBar/TopBar'
import SideBar from './SideBar/SideBar'
// import Place from "../components/Place";
// import ReactDOM from 'react-dom'
import '../stylesheets/style.css'
import HomePage from '../pages/HomePage'
import ProjectTaskWireframe from '../pages/ProjectPages/ProjectTask/ProjectTaskWireframe'
import ProjectOverview from '../pages/ProjectPages/ProjectOverview'
import Finances from '../pages/ProjectPages/Finances'
import ProjectDetails from '../pages/ProjectPages/ProjectDetails'
import Inclusion from '../pages/ProjectPages/Inclusion'
import Documents from '../pages/ProjectPages/Documents'
import History from '../pages/ProjectPages/History'
import Publication from '../pages/ProjectPages/Publication'
import Gdpr from '../pages/ProjectPages/GDPR'
// import Login from '../pages/Login'
import About from '../pages/About'
// import Logout from '../pages/Logout'
import Register from '../pages/Register'
// import Projects from "../pages/Projects";
import { Routes, Route, Outlet } from 'react-router-dom'
import ProjectsOverview from '../pages/ProjectPages/ProjectsOverview'
import Breadcrumbs from './BreadCrumbs/BreadCrumbs.js'
import ButtonsMain from './Buttons/ButtonsMain'
import { ThemeSelectorConsumer } from '../contexts/ThemeContext'
// import PrivateRoute from '../components/LoggedIn'
import Tryout from './StateMachines/tryout'
const Layout = () => {
  return (
    <ThemeSelectorConsumer>
      {({ theme }) => (
        <div id='app' className={'container ' + theme}>
          <div className='item-navbar'>
            <TopBar />
          </div>
          <div className='item-sidebar'>
            <SideBar />
          </div>
          <div className='item-breadcrumb'>
            <Breadcrumbs />
          </div>
          <div className='item-buttons'>
            <ButtonsMain />
          </div>
          <div className='item-main'>
            <Outlet />
          </div>
          <div className='item-footer'>
            @ RLCM
          </div>
        </div>
      )}
    </ThemeSelectorConsumer>
  )
}

class WireFrame extends Component {
  // constructor (props) {
  //   super(props)
  // this.state = { showPopup: false }
  // this.setPopup = this.setPopup.bind(this)
  // }

  // // set state to 'not show pop up'. Rule 41 en 47 nog even bekijken
  // setPopup () {
  //   this.setState({ showPopup: !this.state.showPopup })
  // }

  render () {
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path='/:OrganisationId/about' element={<About />} />
          <Route
            exact
            path='/:OrganisationId/projects'
            element={<ProjectsOverview />}
          />
          <Route path='/:OrganisationId/projects/:ProjectId/tasks/' element={<ProjectTaskWireframe />} />
          <Route path='/:OrganisationId/projects/:ProjectId/finances/' element={<Finances />} />
          <Route
            path='/:OrganisationId/projects/:ProjectId/projectdetails/'
            element={<ProjectDetails />}
          />
          <Route path='/:OrganisationId/projects/:ProjectId/inclusion/' element={<Inclusion />} />
          <Route path='/:OrganisationId/projects/:ProjectId/documents/' element={<Documents />} />
          <Route path='/:OrganisationId/projects/:ProjectId/GDPR/' element={<Gdpr />} />
          <Route path='/:OrganisationId/projects/:ProjectId/Publication/' element={<Publication />} />
          <Route path='/:OrganisationId/projects/:ProjectId/History/' element={<History />} />
          <Route path='/:OrganisationId/projects/:ProjectId' element={<ProjectOverview />} />
          <Route path='/:OrganisationId/projects/:ProjectId/overview/' element={<ProjectOverview />} />
          <Route path='/register' element={<Register />} />
          <Route path='/home' element={<HomePage />} />
          <Route path='/state' element={<Tryout />} /> 
          <Route path='/' element={<HomePage />} />
          <Route path='*' element={<HomePage />} />
        </Route>
      </Routes>
    )
  }
}

export default WireFrame
