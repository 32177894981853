import React, { Component } from 'react'

class Finances extends Component {
  render () {
    return (
      <div className='item-main'>
        <h1>Welcome. This is the Finances page</h1>
      </div>
    )
  }
}

export default Finances
