import React, { useState, useEffect } from 'react'
import { getUserSettings, updateUserSettings } from '../api/usersettings'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const ProjectOrderContext = React.createContext()

export const ProjectOrderProvider = ({ children }) => {
  const [projectOrder, setProjectOrder] = useState(null)
  const token = useSelector((state) => state.auth.token)

  const changeProjectOrder = () => {
    if (projectOrder === 'dark') {
      updateUserSettings({ 'userSettings.Theme': 'light' }, token).then((result) => {
        if (result) {
          setProjectOrder('light')
        }
      })
    } else {
      updateUserSettings({ 'userSettings.Theme': 'dark' }, token).then((result) => {
        if (result) {
          setProjectOrder('dark')
        }
      })
    }
  }

  ProjectOrderProvider.propTypes = { children: PropTypes.object }

  useEffect(() => {
    let isMounted = true
    getUserSettings(token).then((result) => {
      if (isMounted) {
        setProjectOrder(result.ProjectOrder)
      }
    })
    return () => {
      isMounted = false
    }
  }, [token])

  return (
    <ProjectOrderContext.Provider value={{ changeProjectOrder, projectOrder }}>
      {children}
    </ProjectOrderContext.Provider>
  )
}

export const ProjectOrderConsumer = ProjectOrderContext.Consumer

export default ProjectOrderContext
