import React, { useState, useEffect } from 'react'
import '../stylesheets/login-style.css'
import GoogleIcon from '../components/Icons/GoogleIcon'
import LaptopIcon from '../components/Icons/LaptopIcon'
import IdcardIcon from '../components/Icons/IdcardIcon'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../images/logo.png'
import useForm from '../components/Forms/useForm'
import FormInput from '../components/Forms/FormInput'
import SubmitButton from '../components/Forms/SubmitButton'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from '../flux/actions/authActions'
const Login = (props) => {
  const { values, handleChange } = useForm({
    initialValues: {
      username: '',
      password: ''
    }
  })
  const [msg, setMsg] = useState(null)

  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault()
    props.login(values)
  }

  useEffect(() => {
    const { isAuthenticated, isLoading, error } = props
    // Check for register error
    if (error.id === 'LOGIN_FAIL') {
      setMsg(error.msg.msg)
    } else {
      setMsg(null)
    }
    if (isAuthenticated && !isLoading ){
      navigate('/home')
    }
  }, [props])

  return (
    <div className='login-container'>
      <div className='login-box'>
        {msg
          ? (<h2 color='red'>{msg}</h2>)
          : null}
        <div className='login-logo'>
          <img src={logo} alt='Logo' />
        </div>
        <div className='login-right'>
          <p className='input-text'>Login through</p>
          <div className='section input-google'>
            <button
              className='inputbox'
              onClick={() => { return null }}
            >
              <GoogleIcon /> Log In With Google
            </button>
          </div>
          <div className='section input-microsoft'>
            <button
              className='inputbox'
              onClick={() => { return null }}
            >
              <LaptopIcon /> Log In With
              Azure
            </button>
          </div>
          <div className='section input-surf'>
            <button
              className='inputbox'
              onClick={() => { return null }}
            >
              {' '}
              <IdcardIcon /> Log In With Surf
            </button>
          </div>
        </div>
        <div className='login-left'>
          <p className='input-text'>Login with username and password</p>
          {/* <div className='inlineForm__notif'>
            {error && <Error error={error.messages} />}
          </div> */}
          <form onSubmit={handleLogin}>
            <FormInput
              type='text'
              className='input '
              placeholder='Username'
              name='username'
              value={values.username}
              handleChange={handleChange}
            />
            <FormInput
              type='password'
              className='input '
              placeholder='Password'
              name='password'
              value={values.password}
              handleChange={handleChange}
            />
            <div className='inlineForm__submit'>
              <SubmitButton
                name='login' type='submit'
              />
            </div>
          </form>
          {/* <div className='input-username'>
            <input
              type='email'
              name='email'
              className=' fontAwesome inputbox '
              placeholder='&#xF007; username'
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </div>
          <div className='input-password'>
            <input
              type='password'
              name='password'
              className='fontAwesome inputbox '
              placeholder='&#xF023;  password'
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </div>
          <div>
            <button onClick={this.handleSubmit} disabled={!this.validateForm} />
          </div> */}
        </div>
        <div className='login-link'>
          <Link to='/register' className='login-link'>
            register
          </Link>
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  isLoading:  PropTypes.bool,
  error: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
})

export default connect(
  mapStateToProps,
  { login }
)(Login)
