import React, { useState, useEffect } from 'react'
import { getUserSettings, updateUserSettings } from '../api/usersettings'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const LIGHT_THEME = 'light-theme'
const DARK_THEME = 'dark-theme'

// const setCSSVariables = (theme) => {
//   try {
//     document.getElementById('app') ? document.getElementById('app').className = 'container ' + theme : document.getElementById('app1').className = 'container ' + theme
//   } catch (e) {
//     console.log(e)
//   }
// }

const ThemeSelectorContext = React.createContext()

export const ThemeSelectorProvider = ({ children }) => {
  const [themeName, setThemeName] = useState(null)
  const [theme, setTheme] = useState(null)
  const token = useSelector((state) => state.auth.token)

  // const [projectOrder, setProjectOrder] = useState(null)

  const toggleTheme = () => {
    if (theme === DARK_THEME) {
      updateUserSettings({ 'userSettings.Theme': LIGHT_THEME }, token).then((result) => {
        if (result) {
          setTheme(LIGHT_THEME)
          setThemeName(DARK_THEME)
          // setCSSVariables(theme)
        }
      })
    } else {
      updateUserSettings({ 'userSettings.Theme': DARK_THEME }, token).then((result) => {
        if (result) {
          setTheme(DARK_THEME)
          setThemeName(LIGHT_THEME)
          // setCSSVariables(theme)
        }
      })
    }
  }
  useEffect(() => {
    let isMounted = true
    getUserSettings(token).then((result) => {
      if (isMounted) {
        if (result.Theme === DARK_THEME) {
          setThemeName(LIGHT_THEME)
          setTheme(DARK_THEME)
        } else {
          setThemeName(DARK_THEME)
          setTheme(LIGHT_THEME)
        }
        // setCSSVariables(theme)
      }
    })
    return () => {
      isMounted = false
    }
  }, [theme, themeName, token])
  return (
    <ThemeSelectorContext.Provider value={{ toggleTheme, theme, themeName }}>
      {children}
    </ThemeSelectorContext.Provider>
  )
}

ThemeSelectorProvider.propTypes = { children: PropTypes.object }

export const ThemeSelectorConsumer = ThemeSelectorContext.Consumer

export default ThemeSelectorContext
