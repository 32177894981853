import React from 'react'
import useMatchParams from './useMatchParams'
import propTypes from 'prop-types'

const ClassMatchParams = (props) => {
  const useObjectId = useMatchParams
  const Ids = useObjectId()
  const OrganisationId = Ids.OrganisationId
  const ProjectId = Ids.ProjectId
  return (
    <div className='idtext'>
      {props.OrganisationId ? OrganisationId : props.ProjectId ? ProjectId : null}
    </div>
  )
}

ClassMatchParams.propTypes = {
  OrganisationId: propTypes.bool,
  ProjectId: propTypes.bool
}
export default ClassMatchParams
