import React from 'react'
// import ThemeSelectorContext from '../../contexts/ThemeContext'
import logo from '../../images/logo.png'
import SearchBar from './SearchBar'
// import { login } from "../api/logging";
import '../../stylesheets/style.css'
import OrganisationButton from '../DropdownMenus/OrganisationButton'
import UserButton from '../DropdownMenus/UserSettingsButton'

class TopBar extends React.Component {
  render () {
    return (
      <div className='topbar'>
        <div className='logo'>
          <img className='logo' src={logo} alt='Logo' />
        </div>
        <div className='placeholder_organisationmenu'>
          <OrganisationButton />
        </div>
        <SearchBar />
        <UserButton />
      </div>
    )
  }
}

// TopBar.propTypes = {
//   closePopup: PropTypes.object,
//   setPopup: PropTypes.object,
//   showPopup: PropTypes.object,
//   children: PropTypes.object
// }

export default TopBar
