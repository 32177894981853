import React, { useState, useEffect } from 'react'
import { getProjectById } from '../../api/project'

import PropTypes from 'prop-types'

// Dynamic breadcrumb for
const DynamicUserBreadcrumb = ({ match }) => {
  const [dynamicbread, setDynamicbread] = useState('')

  DynamicUserBreadcrumb.propTypes = {
    match: PropTypes.object
  }

  useEffect(() => {
    async function getNameFromId (match) {
      const dynamicbread = await getProjectname(match.params.ProjectId)
      setDynamicbread(dynamicbread)
    }
    getNameFromId(match)
  }, [match])
  const getProjectname = (id) =>
    getProjectById(id).then((result) => {
      return result.FoundedProjectId.projectTitle
    })
  return <span>{dynamicbread}</span>
}

export default DynamicUserBreadcrumb
