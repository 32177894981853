import React, { Component } from 'react'
import {
  Link
} from 'react-router-dom'

class About extends Component {
  render () {
    return (
      <div className='item-main'>,
        <p>This is the about page. Look it has two columns.</p>
        <p>This is a second paragraph and we can use normal html</p>
        <Link to='/' className='fa fa-fw fa-home'>Home</Link>
      </div>
    )
  }
}

export default About
