import fetch from 'node-fetch'

export async function getProjectById (data) {
  const encodedValue = encodeURIComponent(data)
  const response = await fetch(`/api/projects/${encodedValue}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'X-Content-Type-Options': 'nosniff' }
  })
  return await response.json()
}

export async function getProjects () {
  const response = await fetch('/api/projects/overview', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'X-Content-Type-Options': 'nosniff' }
  })
  return await response.json()
}

export async function getProjectOverview (data) {
  const encodedValue = encodeURIComponent(data)
  const response = await fetch(`/api/projects/${encodedValue}/overview`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'X-Content-Type-Options': 'nosniff' }
  })
  const result = await response.json()
  return result
}
