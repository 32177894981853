import React, { Component } from 'react'
import propTypes from 'prop-types'

class ProjectSettings extends Component {
  render () {
    return (
      <div>
        Project Settings
      </div>
    )
  }
}

ProjectSettings.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      ProjectId: propTypes.string,
      UserId: propTypes.string,
      OrganisationId: propTypes.string
    })
  })
}

export default ProjectSettings
