import React from 'react'
import propTypes from 'prop-types'

const FormInput = (props) => {
  return (
    <input
      type={props.type}
      placeholder={props.placeholder}
      className={props.className}
      name={props.name}
      value={props.value}
      onChange={props.handleChange}
      onKeyDown={props.handleKeyDown}
    />
  )
}

FormInput.propTypes = ({
  fail: propTypes.bool,
  type: propTypes.string,
  placeholder: propTypes.string,
  name: propTypes.string,
  value: propTypes.string,
  handleChange: propTypes.func,
  handleKeyDown: propTypes.func,
  className: propTypes.string
})

export default FormInput
