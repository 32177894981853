import React from 'react'
import useMatchParams from '../components/MatchParams/useMatchParams'
import { Link } from 'react-router-dom'

const HomePage = () => {
  const useObjectId = useMatchParams
  const Ids = useObjectId()
  let OrganisationId = Ids.OrganisationId
  OrganisationId = (OrganisationId != null && OrganisationId !== 'home') ? OrganisationId : 'RLCM'
  return (
    <div className='item-main'>
      <Link to={'/' + OrganisationId + '/projects/'} className='sidebarref'>
        {OrganisationId}
      </Link>
    </div>
  )
}

export default HomePage
