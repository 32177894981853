
import {
  UPLOAD_SUCCESS,
  UPLOAD_FAIL,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FAIL,
  ON_NODES_CHANGE,
  ON_EDGES_CHANGE,
  ON_CONNECT,
  ADD_NODE,
  INIT,
  DOWNLOAD_LOADING
} from './types'
import axios from 'axios'
import { returnErrors } from './errorActions'

export const upload = (data, canvas) => dispatch => {
  // Headers
  const encodedValue = encodeURIComponent(data)

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  // Request body
  const body = JSON.stringify({'canvasId':data,'canvasContent': canvas})
  axios
    .post(`/canvas/upload/${encodedValue}`, body, config)
    .then(res =>
      dispatch({
        type: UPLOAD_SUCCESS,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status, 'UPLOAD_FAIL'))
      dispatch({
        type: UPLOAD_FAIL
      })
    })
  
}

export const download = (data) => dispatch => {
  dispatch({
    type: DOWNLOAD_LOADING,
  })
  const encodedValue = encodeURIComponent(data)
  const projection = JSON.stringify({ "canvasContent": 1 })
  const projectionencoded= encodeURIComponent(projection)
  axios
    .get(`/canvas/download/${encodedValue}/${projectionencoded}`)
    .then(res =>{
      dispatch({
        type: DOWNLOAD_SUCCESS,
        payload:JSON.parse(res.data.FoundedCanvas.canvasContent)
      })}
    )
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status, "DOWNLOAD_FAIL"))
      dispatch({
        type: DOWNLOAD_FAIL
      })
    })
}

export const  onNodesChange = (canvas) => dispatch =>{
  dispatch({
    type: ON_NODES_CHANGE,
    payload: canvas
  })
}

export const  onEdgesChange = (canvas) => dispatch =>{
  dispatch({
    type: ON_EDGES_CHANGE,
    payload: canvas
  })
}
export const  onConnect = (canvas) => dispatch =>{
  dispatch({
    type: ON_CONNECT,
    payload: canvas
  })
}

export const  addNode = (canvas) => dispatch =>{
  dispatch({
    type: ADD_NODE,
    payload: canvas
  })
} 

export const  init = (canvas) => dispatch =>{
  dispatch({
    type: INIT,
    payload: canvas
  })
} 