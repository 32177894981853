import React, { Component, Suspense } from 'react'
import ProjectOverviewTable from './ProjectTaskTable'
import propTypes from 'prop-types'
import { Translation } from 'react-i18next'
import Plussign from '../../../components/Icons/Plussign'
import ProjectTaskIcon from '../../../components/Icons/ProjectTaskIcon'
import Tool from '../../../components/Icons/Tool'
import Continue from '../../../components/Icons/Continue'
import Kebab from '../../../components/Icons/Kebab'
import Layers from '../../../components/Icons/Layers'
import Adjust from '../../../components/Icons/Adjust'
import Filter from '../../../components/Icons/Filter'
import { useParams } from 'react-router-dom'

const Loader = () => (
  <div>loading...</div>
)

const WrappedComponent = props => {
  const params = useParams()
  console.log(params)

  return (
    <Suspense fallback={<Loader />}>
      <ProjectOverviewWireframe params={params} {...props} />
    </Suspense>
  )
}

class ProjectOverviewWireframe extends Component {
  render () {
    return (
      <Translation>
        {(t) =>
          <div className='ProjctOverviewContainer'>
            <div className='item-taskmenu'>
              <div className='taskmenu'>
                <div className='taskmenu_to_do'> To Do</div>
                <div className='taskmenu_item_with_icon'>
                  {' '}
                  <Plussign />
                  {t('projectoverviewwireframe.newitem')}
                </div>
                <div className='taskmenu_item_with_icon'>
                  {' '}
                  <ProjectTaskIcon fill='currentColor' />
                  {t('projectoverviewwireframe.board')}
                </div>
                <div className='taskmenu_item_with_icon'>
                  <Tool />
                  {t('projectoverviewwireframe.columnoptions')}
                </div>
                <div className='taskmenu_item_with_icon'>
                  {' '}
                  <Continue />
                  {t('projectoverviewwireframe.continue')}
                </div>
                <div className='taskmenu_item_with_icon'>
                  <Kebab />
                </div>
                <div className='taskmenu_item_with_icon'>
                  {' '}
                  <Layers />
                  {t('projectoverviewwireframe.phase')}
                </div>
                <div className='taskmenu_item_with_icon'>
                  <Adjust />
                </div>
                <div className='taskmenu_item_with_icon'>
                  <Filter />
                </div>
              </div>
            </div>
            <div className='item-tasksoverview-container'>
              <ProjectOverviewTable ProjectId={this.props.params.ProjectId} />
            </div>
          </div>}
      </Translation>
    )
  }
}

ProjectOverviewWireframe.propTypes = {
  params: propTypes.shape({
    ProjectId: propTypes.string.isRequired
  })
}

export default WrappedComponent
