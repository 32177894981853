import React from 'react'

export default function SideBarWorkflow () {
	const onDragStart = (event, nodeType) => {
		event.dataTransfer.setData('application/reactflow', nodeType)
		event.dataTransfer.effectAllowed = 'move'
	}

	return (
		<ul className='nav__list-workflow '>
      <li className='nav__listitem-workflow dndnode input'
				onDragStart={(event) => onDragStart(event, 'input')}
				draggable
			>
				Input Node
        </li>
      <li className='nav__listitem-workflow dndnode input'
				onDragStart={(event) => onDragStart(event, 'default')}
				draggable
			>
				Default Node
        </li>
      <li className='nav__listitem-workflow dndnode output'
				onDragStart={(event) => onDragStart(event, 'output')}
				draggable
			>
				Output Node
      </li>
    </ul>
	)
}
