import React, { useState, useEffect }  from 'react'
import useForm from '../components/Forms/useForm'
import FormInput from '../components/Forms/FormInput'
import SubmitButton from '../components/Forms/SubmitButton'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { register } from '../flux/actions/authActions'

const Register = (props) => {
  const { values, handleChange } = useForm({
    initialValues: {
      username: '',
      password: '',
      email: ''
    }
  })
  const [msg, setMsg] = useState(null)


  useEffect(() => {
    const { error } = props
    // Check for register error
    if (error.id === 'LOGIN_FAIL') {
      setMsg(error.msg.msg)
    } else {
      setMsg(null)
    }
  }, [props])


  const handleRegister = async (e) => {
    e.preventDefault()
    props.register(values)
  }

    return (
      <div className='item-main'>
        <p>This is the Register page. </p>
        {msg
          ? (<h2 color='red'>{msg}</h2>)
          : null}
          <p className='input-text'>register with username, email and password</p>
          <form onSubmit={handleRegister}>
            <FormInput
              type='text'
              className='input '
              placeholder='Username'
              name='username'
              value={values.username}
              handleChange={handleChange}
            />
            <FormInput
              type='text'
              className='input '
              placeholder='Email'
              name='email'
              value={values.email}
              handleChange={handleChange}
            />
            <FormInput
              type='password'
              className='input '
              placeholder='Password'
              name='password'
              value={values.password}
              handleChange={handleChange}
            />
            <div className='inlineForm__submit'>
              <SubmitButton
                name='register' type='submit'
              />
            </div>
          </form>
      </div>

    )
}

Register.propTypes = {
  error: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
})

export default connect(
  mapStateToProps,
  { register }
)(Register)
