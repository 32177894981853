import fetch from 'node-fetch'

export async function getUserSettings (token) {
  if (token == null) {
    return { Projectorder: null, Theme: 'dark' }
  }
  const projection = { userSettings: 1 }
  const response = await fetch(`/api/usersetting/${encodeURIComponent(JSON.stringify(token))}/${encodeURIComponent(JSON.stringify(projection))}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'X-Content-Type-Options': 'nosniff' }
  })
  const result = await response.json()
  try { return result.user.userSettings } catch {
    return { Projectorder: null, Theme: 'dark' }
  }
}

export async function getUserName (token) {
  // console.log(token)
  // if (token === undefined || token === null) {
  //   return { firstName: 'f', lastName: 'l' }
  // }
  const projection = { firstName: 1, lastName: 1 }
  const response = await fetch(`/api/usersetting/${encodeURIComponent(JSON.stringify(token))}/${encodeURIComponent(JSON.stringify(projection))}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'X-Content-Type-Options': 'nosniff' }
  })
  const result = await response.json()
  // console.log(result, token)
  try { return result.user } catch {
    return { firstName: 'f', lastName: 'l' }
  }
}

export async function updateUserSettings (field, token) {
  // console.log(token)
  const fields = field
  const result = await fetch(`/api/usersetting/${encodeURIComponent(JSON.stringify(token))}/${encodeURIComponent(JSON.stringify(fields))}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'X-Content-Type-Options': 'nosniff' }
  })
  const outcome = await result.json()
  try {
    if (outcome.user.userSettings.Theme !== null) {
      return true
    }
  } catch {
    return false
  }
}
