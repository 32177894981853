import React, { useContext, Suspense } from 'react'
import propTypes from 'prop-types'
import ThemeSelectorContext from '../../contexts/ThemeContext'
import { useTranslation } from 'react-i18next'

const UserSettingsPage = () => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }
  const { toggleTheme, themeName } = useContext(ThemeSelectorContext)
  return (
    <div>
      {t('usersettings.theme.change')}
      <button onClick={toggleTheme}>
        {themeName}
      </button>
      {t('usersettings.language.change')}
      <button type='button' onClick={() => changeLanguage('nl')}>
        nl
      </button>
      <button type='button' onClick={() => changeLanguage('en')}>
        en
      </button>
    </div>

  )
}

UserSettingsPage.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      ProjectId: propTypes.string,
      UserId: propTypes.string,
      OrganisationId: propTypes.string
    })
  })
}

// loading component for suspense fallback
const Loader = () => (
  <div>loading...</div>
)

// here app catches the suspense from page in case translations are not yet loaded
export default function UserSettings () {
  return (
    <Suspense fallback={<Loader />}>
      <UserSettingsPage />
    </Suspense>
  )
}
