import React, { useState, useEffect } from 'react'
import { getUserName } from '../../api/usersettings'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

// Dynamic breadcrumb for
const DynamicUserBreadcrumb = ({ match }) => {
  const [dynamicbread, setDynamicbread] = useState('')
  const token = useSelector((state) => state.auth.token)

  DynamicUserBreadcrumb.propTypes = {
    match: PropTypes.object
  }

  useEffect(() => {
    let isMounted = true
    async function getNameFromId (match, token) {
      if (isMounted) {
        // let dynamicbread = match.params.UserId
        getUserName(token).then((result) => {
          setDynamicbread(result.firstName)
        })
      }
    }
    getNameFromId(match, token)
    return () => {
      isMounted = false
    }
  }, [match, token])
  return <span>{dynamicbread}</span>
}

export default DynamicUserBreadcrumb
