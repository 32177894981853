import React, { useState, useEffect } from 'react'
import { getUserName } from '../../api/usersettings'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Logout from '../../pages/Logout'
import { useSelector } from 'react-redux'

function UserSettingMenuDropDown () {
  const [userId, setuserId] = useState('')
  const { t } = useTranslation()
  const token = useSelector((state) => state.auth.token)

  useEffect(() => {
    if (!userId) {
      getUserName(token).then((result) =>
        setuserId(result._id))
    }
  }, [userId, token])

  return (
    <div className='UserSettingMenuDropDown'>
      <div className='UserSettingItem'>
        <Logout />
      </div>
      <div className='UserSettingItem'>
        <Link to={'/settings/users/' + userId}>
          {t('settings')}
        </Link>
      </div>
    </div>
  )
}

export default UserSettingMenuDropDown
