export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_FAIL = 'UPLOAD_FAIL'
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAIL = 'DOWNLOAD_FAIL'
export const ON_NODES_CHANGE = 'ON_NODES_CHANGE'
export const ON_EDGES_CHANGE = 'ON_EDGES_CHANGE'
export const ADD_NODE = 'ADD_NODE'
export const ON_CONNECT = 'ON_CONNECT'
export const INIT = 'INIT'
export const DOWNLOAD_LOADING = 'DOWNLOAD_LOADING'
