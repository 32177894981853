import {
  UPLOAD_SUCCESS,
  UPLOAD_FAIL,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FAIL,
  ON_NODES_CHANGE,
  ON_EDGES_CHANGE,
  ON_CONNECT,
  ADD_NODE,
  INIT,
  DOWNLOAD_LOADING
} from '../actions/types'
import {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges
} from 'reactflow'

const initialState = {
  loading:false,
  success: false,
  nodes:[],
  edges:[]
}

export default function draggableReducer (state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_LOADING:
      return {
        ...state,
        loading:true,
        success:false
      }
    case UPLOAD_SUCCESS: // check if want similar construcion as download with the loading
    case UPLOAD_FAIL:
    case DOWNLOAD_FAIL:
      return {
        ...state,
        loading:false
      }
    case DOWNLOAD_SUCCESS:
    case INIT:
      console.log(action.payload)
      return {
        loading:false,
        success:true,
        nodes: action.payload.nodes,
        edges: action.payload.edges
        }
    case ON_NODES_CHANGE:
      return{
      ...state,
      nodes: applyNodeChanges(action.payload, state.nodes),
      }
    case ON_EDGES_CHANGE:
      return{
      ...state,
      edges: applyEdgeChanges(action.payload, state.edges)
      }
    case ON_CONNECT:
        return{
        ...state,
        edges: state.edges.concat(addEdge(action.payload, state.edges))
        }
    case ADD_NODE:
      return{
        ...state,
      nodes:  state.nodes.concat(action.payload)
      }
    default:
      return state
  }
}

