import React from 'react'
import { NavLink } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import UserBreadCrumb from './UserBreadCrumb'
import ProjectBreadCrumb from './ProjectBreadCrumb'

// past dynamic breadcrumb to

const routes = [{ path: '/settings/users/:UserId', breadcrumb: UserBreadCrumb }, { path: '/:OrganisationId/projects/:ProjectId', breadcrumb: ProjectBreadCrumb }, { path: '/settings/organisations/:OrganisationId/projects/:ProjectId', breadcrumb: ProjectBreadCrumb }]
const Options = {
  excludePaths: [''] // disable default breadcrumb generation for specific paths  // disable all default breadcrumb generation
}
const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes, Options)
  return (
    <div>
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <ul className='breadcrumb' key={match.pathname}>
          <li><NavLink to={match.pathname}> {breadcrumb}</NavLink> </li>
        </ul>
      ))}
    </div>
  )
}

export default Breadcrumbs
