import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

const ProjectHistoryTextFunc = () => {
  const { t } = useTranslation()

  return (
    <div className='text_sidebar'>
      {t('sidebar.projecthistory')}
    </div>
  )
}
const Loader = () => (
  <div>loading...</div>
)

// here app catches the suspense from page in case translations are not yet loaded
export default function ProjectHistoryText () {
  return (
    <Suspense fallback={<Loader />}>
      <ProjectHistoryTextFunc />
    </Suspense>
  )
}
