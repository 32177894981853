import React, { Suspense } from 'react'
import { useLocation, useMatch } from 'react-router'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Plussign from '../Icons/Plussign'

const ButtonsMainPage = () => {
  const { t } = useTranslation()
  const match = useMatch({ path: '/projects/project/:ProjectId' })
  const ProjectId = match ? match.params.ProjectId : null
  const location = useLocation()
  const buttonText = ProjectId != null ? 'member' : location.pathname.includes('tasks') ? 'task' : location.pathname.includes('project') ? 'project' : 'organisation'

  return (
    <button className='buttonsMain' type='button'>
      <Plussign />{' '}
      {t('buttonmain.' + buttonText)}
    </button>
  )
}

ButtonsMainPage.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      ProjectId: propTypes.string
    })
  }),
  location: propTypes.object
}

// loading component for suspense fallback
const Loader = () => (
  <div>loading...</div>
)

export default function ButtonsMain () {
  return (
    <Suspense fallback={<Loader />}>
      <ButtonsMainPage />
    </Suspense>
  )
}
