import React from 'react'
import { connect } from 'react-redux'
import { logout } from '../flux/actions/authActions'
import PropTypes from 'prop-types'

const Logout = (props) => {
  return (
    <button onClick = {props.logout}>
      Logout
    </button>
  )
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired
}
export default connect(
  null,
  { logout }
)(Logout)
