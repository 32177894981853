import React, { useEffect, useState } from 'react'
import { getProjectById } from '../../../api/project'
import useMatchParams from '../../MatchParams/useMatchParams'
import propTypes from 'prop-types'
import { getUserName } from '../../../api/usersettings'
import { useSelector } from 'react-redux'

const TopSideBarText = () => {
  const [buttonText, setButtonText] = useState('')
  const useObjectId = useMatchParams
  const Ids = useObjectId()
  const OrganisationId = Ids.OrganisationId
  const ProjectId = Ids.ProjectId
  const UserId = Ids.UserId
  const token = useSelector((state) => state.auth.token)

  useEffect(() => {
    let isMounted = true // note mutable flag
    async function getNameFromId (ProjectId, OrganisationId) {
      // <span>{location.projectid? location.projectid.project.Project_Title : match.params.projectid}</span>
      const buttonText = ProjectId != null ? await getProjectname(ProjectId) : OrganisationId != null ? OrganisationId : await getUserName(token).then((result) => { return result.firstName })
      if (isMounted) setButtonText(buttonText)
    }
    getNameFromId(ProjectId, OrganisationId, UserId)
    return () => { isMounted = false }
  }, [ProjectId, OrganisationId, UserId, token])

  const getProjectname = (id) =>
    getProjectById(id).then((result) => {
      return result.FoundedProjectId.projectTitle
    })

  return (
    <div className='text_sidebar'>
      {buttonText}{' '}
    </div>
  )
}

TopSideBarText.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      ProjectId: propTypes.string
    })
  }),
  location: propTypes.object
}

export default TopSideBarText
