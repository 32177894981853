import React from 'react'
import useMatchParams from '../MatchParams/useMatchParams'
import { Link } from 'react-router-dom'

function OrganisationMenuDropDown () {
  const useObjectId = useMatchParams
  const Ids = useObjectId()
  let OrganisationId = Ids.OrganisationId
  OrganisationId = (OrganisationId != null && OrganisationId !== 'home') ? OrganisationId : 'RLCM'
  return (
    <div className='OrganisationMenuDropDown'>
      <div className='OverviewOrganisations'>
        <Link to='/home' className='sidebarref'>
          Home
        </Link>
        <Link to='/workflow/canvas' className='sidebarref'>
          Workflow
        </Link>
        <Link to={'/' + OrganisationId + '/projects/'} className='sidebarref'>
          {OrganisationId}
        </Link>
      </div>
    </div>
  )
}

export default OrganisationMenuDropDown
