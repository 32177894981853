import React, { Component, Suspense } from 'react'
import propTypes from 'prop-types'
import { getProjectOverview } from '../../../api/project'
import { Translation } from 'react-i18next'

class ProjectOverviewTableFunc extends Component {
  constructor (props) {
    super(props)
    this.renderTable = this.renderTable.bind(this)
    this.makeRow = this.makeRow.bind(this)
    this.handleRowClick = this.handleRowClick.bind(this)
    this.renderItemCaret = this.renderItemCaret.bind(this)
    this.handleContractLayer = this.handleContractLayer.bind(this)
    this.handleExpandLayer = this.handleExpandLayer.bind(this)
    this.getProjectOverviewData = this.getProjectOverviewData.bind(this)
    this.cleanOverviewdata = this.cleanOverviewdata.bind(this)

    this.state = {
      expandedRows: [],
      datamodified: null,
      phases: [],
      tasks: [],
      subtasks: [],
      data: null
    }
  }

  handleExpandLayer () {
    if (this.state.phases.every(phase => this.state.expandedRows.includes(phase))) {
      if (this.state.tasks.every(task => this.state.expandedRows.includes(task))) {
        const newExpandedRows = this.state.expandedRows.concat(this.state.subtasks)
        this.setState({ expandedRows: newExpandedRows })
      } else {
        const newExpandedRows = this.state.expandedRows.concat(this.state.tasks)
        this.setState({ expandedRows: newExpandedRows })
      }
    } else {
      const newExpandedRows = this.state.expandedRows.concat(this.state.phases)
      this.setState({ expandedRows: newExpandedRows })
    }
  }

  handleContractLayer () {
    if (!this.state.tasks.some(task => this.state.expandedRows.includes(task))) {
      const newExpandedRows = this.state.expandedRows.filter(el => !this.state.phases.includes(el))
      this.setState({ expandedRows: newExpandedRows })
    } else {
      const newExpandedRows = this.state.expandedRows.filter(el => !this.state.tasks.includes(el))
      this.setState({ expandedRows: newExpandedRows })
    }
  }

  handleRowClick (rowId) {
    const currentExpandedRows = this.state.expandedRows
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId)

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId)

    this.setState({ expandedRows: newExpandedRows })
  }

  renderItemCaret (rowdata) {
    const currentExpandedRows = this.state.expandedRows
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowdata.id)
    const clickCallback = () => this.handleRowClick(rowdata.id)
    if (!rowdata.children) { return }
    if (isRowCurrentlyExpanded) {
      return (
        <svg onClick={rowdata.children ? clickCallback : null} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' tabIndex='1' className='bi bi-chevron-compact-down' viewBox='0 0 16 16'>
          <path fillRule='evenodd' d='M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z' />
        </svg>
      )
    } else {
      return (
        <svg onClick={rowdata.children ? clickCallback : null} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-chevron-compact-right' viewBox='0 0 16 16'>
          <path fillRule='evenodd' d='M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z' />
        </svg>
      )
    }
  }

  makeRow (data) {
    return (
      <tr key={data.id}>
        {/* <td className='ProjectOverviewTableColumn'>{index + 1}</td> */}
        <td />
        <td className='ProjectOverviewTableColumn' style={{ paddingLeft: data.type === 'Task' ? '5px' : data.type === 'Subtask' ? '30px' : '0px' }}> {data.type}</td>
        <td className='ProjectOverviewTableColumn' style={{ paddingLeft: data.type === 'Task' ? '5px' : data.type === 'Subtask' ? '30px' : '0px' }}>{this.renderItemCaret(data)}{` ${data.title}`}</td>
        <td className='ProjectOverviewTableColumn'>{data.state}</td>
        <td className='ProjectOverviewTableColumn'>{data.tags}</td>
        {/* <td className='ProjectOverviewTableColumn'>{data.description}</td> */}
      </tr>
    )
  }

  // Niet super mooie code, kan keertjje kijken of we van de drie dubbele loop af kunnen, maar het is wel snel en werkt!
  renderTable () {
    const rows = []
    if (!this.state.datamodified) {
      return (
        <tbody className='ProjectOverviewTableBody' />
      )
    }
    for (const [index, phase] of this.state.datamodified.dataphases.entries()) {
      rows.push(this.makeRow(phase, index))
      if (phase.tasks) {
        for (const [index2, task] of phase.tasks.entries()) {
          if (this.state.expandedRows.includes(phase.id)) {
            rows.push(this.makeRow(task, index2))
          }
          if (task.subtasks) {
            for (const [index3, subtask] of task.subtasks.entries()) {
              if (this.state.expandedRows.includes(task.id) && this.state.expandedRows.includes(phase.id)) {
                rows.push(this.makeRow(subtask, index3))
              }
            }
          }
        }
      }
    }
    return (
      <tbody className='ProjectOverviewTableBody'>
        {rows}
      </tbody>
    )
  }

  getProjectOverviewData () { // adjust for different project values
    try {
      getProjectOverview(this.props.ProjectId).then((responseJson) => {
        console.log(responseJson)
        if (this._isMounted) {
          this.setState({
            data: responseJson.data
          })
        }
        this.cleanOverviewdata()
      })
    } catch (e) {
      console.log(e)
    }
  }

  cleanOverviewdata () {
    const datamodified = this.state.data
    console.log(datamodified)
    const phases = []
    const tasks = []
    const subtasks = []
    if (datamodified) {
      for (const [indexphase, phase] of this.state.data.dataphases.entries()) {
        phases.push(phase.id)
        if (phase.tasks) {
          for (const [indextask, task] of phase.tasks.entries()) {
            tasks.push(task.id)
            datamodified.dataphases[indexphase].children ? datamodified.dataphases[indexphase].children.push(task.id) : datamodified.dataphases[indexphase].children = [task.id]
            datamodified.dataphases[indexphase].tasks[indextask].parent = phase.id
            if (task.subtasks) {
              for (const [indexsubtask, subtask] of task.subtasks.entries()) {
                subtasks.push(subtask.id)
                datamodified.dataphases[indexphase].tasks[indextask].children ? datamodified.dataphases[indexphase].tasks[indextask].children.push(subtask.id) : datamodified.dataphases[indexphase].tasks[indextask].children = [subtask.id]
                datamodified.dataphases[indexphase].tasks[indextask].subtasks[indexsubtask].parent = phase.id
              }
            }
          }
        }
      }
    }
    if (this._isMounted) {
      this.setState({ datamodified: datamodified, phases: phases, tasks: tasks, subtasks: subtasks })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidMount () {
    this._isMounted = true
    this.getProjectOverviewData()
  }

  render () {
    const tablebody = this.state.data ? this.renderTable() : <tbody><tr><td><p>No Data Found</p></td></tr></tbody>
    return (
      <Translation>
        {(t) =>
          <table className='ProjectOverviewTable'>
            <thead className='ProjectOverviewTableHeaders'>
              <tr>
                <th className='ProjectOverviewTableOrder'>
                  <svg onClick={this.handleExpandLayer} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-plus-square' viewBox='0 0 16 16'>
                    <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                  </svg>
                  <svg onClick={this.handleContractLayer} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-dash-square' viewBox='0 0 16 16'>
                    <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                    <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' />
                  </svg>
                </th>
                <th className='ProjectOverviewTableType'> {t('projectoverviewtable.type')} </th>
                <th className='ProjectOverviewTableTitle'> {t('projectoverviewtable.title')} </th>
                <th className='ProjectOverviewTableState'> {t('projectoverviewtable.state')} </th>
                <th className='ProjectOverviewTableTag'> {t('projectoverviewtable.tag')} </th>
                {/* <th className='ProjectOverviewTableTag'> Description </th> */}
              </tr>
            </thead>
            {tablebody}
          </table>}
      </Translation>
    )
  }
}

ProjectOverviewTableFunc.propTypes = {
  ProjectId: propTypes.string.isRequired
}

const Loader = () => (
  <div>loading...</div>
)

// here app catches the suspense from page in case translations are not yet loaded
export default function ProjectOverviewTable (params) {
  console.log(params)
  return (
    <Suspense fallback={<Loader />}>
      <ProjectOverviewTableFunc ProjectId={params.ProjectId} />
    </Suspense>
  )
}
