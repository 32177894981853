import React, { Suspense } from 'react'
import useMatchParams from '../../MatchParams/useMatchParams'
import { useTranslation } from 'react-i18next'

const ProjectSettingsTextFunc = () => {
  const { t } = useTranslation()
  const useObjectId = useMatchParams
  const Ids = useObjectId()
  const ProjectId = Ids.ProjectId
  const ButtonText = ProjectId ? 'projectsettings' : 'organisationsettings'

  return (
    <div className='text_sidebar'>
      {t('sidebar.projectsettings.' + ButtonText)}
    </div>
  )
}
const Loader = () => (
  <div>loading...</div>
)

// here app catches the suspense from page in case translations are not yet loaded
export default function ProjectSettingsText () {
  return (
    <Suspense fallback={<Loader />}>
      <ProjectSettingsTextFunc />
    </Suspense>
  )
}
