import React, { Component } from 'react'
import { getProjects } from '../../api/project'
// import ProjectOrderContext from '../../api/contexts/ProjectOrderContext'
import '../../stylesheets/style.css'
import ProjectOverviewIcon from '../../components/Icons/ProjectOverviewIcon'
import ProjectTaskIcon from '../../components/Icons/ProjectTaskIcon'
import ProjectFinancesIcon from '../../components/Icons/ProjectFinancesIcon'
import ProjectDetailsIcon from '../../components/Icons/ProjectDetailsIcon'
import ProjectInclusionIcon from '../../components/Icons/ProjectInclusionIcon'
import ProjectDocumentsIcon from '../../components/Icons/ProjectDocumentsIcon'
import ProjectGDPRIcon from '../../components/Icons/ProjectGDPRIcon'
import ProjectPublicationIcon from '../../components/Icons/ProjectPublicationIcon'
import ProjectHistoryIcon from '../../components/Icons/ProjectHistoryIcon'
import ProjectSettingsIcon from '../../components/Icons/ProjectSettingsIcon'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'

class ProjectsOverview extends Component {
  // static contextType = ProjectOrderContext;
  constructor (props) {
    super(props)
    this.getProjectsOverview()
    this.state = { projects: null }
    this.getProjectsOverview = this.getProjectsOverview.bind(this)
  }

  componentDidMount () {
    this._isMounted = true
    this.getProjectsOverview()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getProjectsOverview () {
    getProjects().then((result) => {
      if (this._isMounted) {
        this.setState({ projects: result })
      }
    })
  }

  render () {
    let tableBody = <div />
    if (this.state.projects) {
      tableBody = (
        <div className='projectoverview'>
          {this.state.projects.map((project) => (
            <ProjectRow key={project._id} project={project} />
          ))}
        </div>
      )
    }

    return (
      <div>
        <h1>Project Title {this.context.projectOrder}</h1>
        {tableBody}
      </div>
    )
  }
}

class ProjectRow extends Component {
  render () {
    const ProjectId = this.props.project._id
    const OrganisationId = 'RLCM'
    return (
      <div className='projectwidget'>
        <div className='projectlink'>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/overview'}>
            {' '}
            {this.props.project.projectTitle}
          </Link>
        </div>
        <div className='projecticons'>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/overview'}>
            <ProjectOverviewIcon />
          </Link>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/task'}>
            <ProjectTaskIcon />
          </Link>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/finances'}>
            <ProjectFinancesIcon />
          </Link>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/details'}>
            <ProjectDetailsIcon />
          </Link>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/inclusion'}>
            <ProjectInclusionIcon />
          </Link>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/documents'}>
            <ProjectDocumentsIcon />
          </Link>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/GDPR'}>
            <ProjectGDPRIcon />
          </Link>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/publication'}>
            <ProjectPublicationIcon />
          </Link>
          <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/history'}>
            <ProjectHistoryIcon />
          </Link>
          <Link to={'/settings/organisations/' + OrganisationId + '/projects/' + ProjectId}>
            <ProjectSettingsIcon ProjectId={ProjectId} />
          </Link>
        </div>
      </div>
    )
  }
}

ProjectRow.propTypes = {
  project: propTypes.object,
  _i: propTypes.object
}

export default ProjectsOverview
