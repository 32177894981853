import React, { useEffect } from 'react'
import './stylesheets/style.css'
import Login from './pages/Login'
import Logout from './pages/Logout'
import WireFrame from './components/WireFrame'
import { ThemeSelectorProvider } from './contexts/ThemeContext'
import { SideSelectorProvider } from './contexts/SideBarContext'
import { ProjectOrderProvider } from './contexts/ProjectOrderContext'
import PrivateRoute from './components/LoggedIn'
import WireFrameSettings from './components/WireFrameSettings'
import WireFrameWorkflow from './components/WireFrameWorkflow'
import { Provider } from 'react-redux'
import store from './store'
import { loadUser } from './flux/actions/authActions'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'

function App () {
  useEffect(() => {
    store.dispatch(loadUser())
  })

  return (
    <Router>
      <Provider store={store}>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/auth/logout' element={<Logout />} />
          <Route element={<PrivateRoute />}>
            <Route
              path='/settings/*' element={
                <SideSelectorProvider>
                  <ThemeSelectorProvider>
                    <ProjectOrderProvider>
                      <WireFrameSettings />
                    </ProjectOrderProvider>
                  </ThemeSelectorProvider>
                </SideSelectorProvider>
                }
            />
            <Route
              path='/workflow/*' element={
                <SideSelectorProvider>
                  <ThemeSelectorProvider>
                    <ProjectOrderProvider>
                      <WireFrameWorkflow />
                    </ProjectOrderProvider>
                  </ThemeSelectorProvider>
                </SideSelectorProvider>
                }
            />
            <Route
              path='/*' element={
                <SideSelectorProvider>
                  <ThemeSelectorProvider>
                    <ProjectOrderProvider>
                      <WireFrame />
                    </ProjectOrderProvider>
                  </ThemeSelectorProvider>
                </SideSelectorProvider>
                }
            />
          </Route>
        </Routes>
      </Provider>
    </Router>

  )
}

export default App
