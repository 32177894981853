import { combineReducers } from 'redux'
import errorReducer from './errorReducer'
import authReducer from './authReducer'
import draggableReducer from './draggableReducers'

export default combineReducers({
  error: errorReducer,
  auth: authReducer,
  draggable: draggableReducer
})
