import React, { Component } from 'react'
import TopBar from './TopBar/TopBar'
import SideBarWorkflow from './SideBar/SideBarWorkflow'
import '../stylesheets/style-workflow.css'
import { Routes, Route, Outlet } from 'react-router-dom'
import { ThemeSelectorConsumer } from '../contexts/ThemeContext'
import WorkflowCanvas from '../pages/WorkflowPages/WorkflowCanvas'

// import PrivateRoute from '../components/LoggedIn'
const Layout = () => {
  return (
    <ThemeSelectorConsumer>
      {({ theme }) => (
        <div id='app' className={'container-workflow ' + theme}>
          <div className='item-navbar-workflow'>
            <TopBar />
          </div>
          <div className='item-sidebar-workflow'>
            <SideBarWorkflow />
          </div>
          <div className='item-main-workflow'>
            <Outlet />
          </div>
          <div className='item-footer-workflow'>
            @ RLCM
          </div>
        </div>
      )}
    </ThemeSelectorConsumer>
  )
}

class WireFrame extends Component {
  render () {
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route
            path='/canvas' element={
              <WorkflowCanvas />
            }
          />
        </Route>
      </Routes>
    )
  }
}

export default WireFrame
