import React, { useContext } from 'react'
import SideSelectorContext from '../../contexts/SideBarContext'
import TopSideBarIcon from '../Icons/TopSideBarIcon'
import ProjectOverviewIcon from '../Icons/ProjectOverviewIcon'
import ProjectTaskIcon from '../Icons/ProjectTaskIcon'
import ProjectFinancesIcon from '../Icons/ProjectFinancesIcon'
import ProjectDetailsIcon from '../Icons/ProjectDetailsIcon'
import ProjectInclusionIcon from '../Icons/ProjectInclusionIcon'
import ProjectDocumentsIcon from '../Icons/ProjectDocumentsIcon'
import ProjectGDPRIcon from '../Icons/ProjectGDPRIcon'
import ProjectPublicationIcon from '../Icons/ProjectPublicationIcon'
import ProjectHistoryIcon from '../Icons/ProjectHistoryIcon'
import ProjectSettingsIcon from '../Icons/ProjectSettingsIcon'
import TopSideBarText from './IconText/TopSideBarText'
import ProjectOverviewText from './IconText/ProjectOverviewText'
import ProjectTaskText from './IconText/ProjectTaskText'
import ProjectFinancesText from './IconText/ProjectFinancesText'
import ProjectDetailsText from './IconText/ProjectDetailsText'
import ProjectInclusionText from './IconText/ProjectInclusionText'
import ProjectDocumentsText from './IconText/ProjectDocumentsText'
import ProjectGDPRText from './IconText/ProjectGDPRText'
import ProjectPublicationText from './IconText/ProjectPublicationText'
import ProjectHistoryText from './IconText/ProjectHistoryText'
import ProjectSettingsText from './IconText/ProjectSettingsText'
import CarotsLeft from '../Icons/CarotsLeft'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useMatchParams from '../MatchParams/useMatchParams'

const SideBar = () => {
  const { toggleSide } = useContext(SideSelectorContext)
  const useObjectId = useMatchParams
  const Ids = useObjectId()
  let OrganisationId = Ids.OrganisationId
  const ProjectIds = Ids.ProjectId
  const UserIds = Ids.UserId
  const UserId = UserIds
  const ProjectId = ProjectIds != null ? ProjectIds : '6037d59f99451e0d38d73a50'
  OrganisationId = OrganisationId != null ? OrganisationId : 'RLCM'

  return (
    <ul className='nav__list'>
      <li className='nav__listitem'>
        <div className='bold'>
          <Link
            to={
              UserIds
                ? '/settings/users/' + UserId
                : ProjectIds
                  ? '/' + OrganisationId + '/projects/' + ProjectId + '/overview'
                  : '/' + OrganisationId + '/projects/'
              } className='sidebarref'
          >
            <TopSideBarIcon />
            <TopSideBarText />
          </Link>
        </div>
      </li>
      <li className='nav__listitem'>
        <hr className='hr' />
      </li>
      <li className='nav__listitem'>
        <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/overview'} className='sidebarref'>
          <ProjectOverviewIcon />
          <ProjectOverviewText />
        </Link>
      </li>
      <li className='nav__listitem'>
        <div className='empty' />
      </li>
      <li className='nav__listitem'>
        <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/tasks'} className='sidebarref'>
          <ProjectTaskIcon />
          <ProjectTaskText />
        </Link>
      </li>
      <li className='nav__listitem'>
        <div className='empty' />
      </li>
      <li className='nav__listitem'>
        <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/finances'} className='sidebarref'>
          <ProjectFinancesIcon />
          <ProjectFinancesText />
        </Link>
      </li>
      <li className='nav__listitem'>
        <div className='empty' />
      </li>
      <li className='nav__listitem'>
        <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/projectdetails'} className='sidebarref'>
          <ProjectDetailsIcon />
          <ProjectDetailsText />
        </Link>
      </li>
      <li className='nav__listitem'>
        <div className='empty' />
      </li>
      <li className='nav__listitem'>
        <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/inclusion'} className='sidebarref'>
          <ProjectInclusionIcon />
          <ProjectInclusionText />
        </Link>
      </li>
      <li className='nav__listitem'>
        <div className='empty' />
      </li>
      <li className='nav__listitem'>
        <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/documents'} className='sidebarref'>
          <ProjectDocumentsIcon />
          <ProjectDocumentsText />
        </Link>
      </li>
      <li className='nav__listitem'>
        <div className='empty' />
      </li>
      <li className='nav__listitem'>
        <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/GDPR'} className='sidebarref'>
          <ProjectGDPRIcon />
          <ProjectGDPRText />
        </Link>
      </li>
      <li className='nav__listitem'>
        <div className='empty' />
      </li>
      <li className='nav__listitem'>
        <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/publication'} className='sidebarref'>
          <ProjectPublicationIcon />
          <ProjectPublicationText />
        </Link>
      </li>
      <li className='nav__listitem'>
        <div className='empty' />
      </li>
      <li className='nav__listitem'>
        <Link to={'/' + OrganisationId + '/projects/' + ProjectId + '/history'} className='sidebarref'>
          <ProjectHistoryIcon />
          <ProjectHistoryText />
        </Link>
      </li>
      <li className='nav__listitem'>
        <div className='empty' />
      </li>
      <li className='nav__listitem'>
        <hr className='hr' />
      </li>
      <li className='nav__listitem'>
        <div className='sidebarref_small_text'>
          <Link to={ProjectIds != null ? '/settings/organisations/' + OrganisationId + '/projects/' + ProjectId : '/settings/organisations/' + OrganisationId} className='sidebarref'>
            <ProjectSettingsIcon />
            <ProjectSettingsText />
          </Link>
          <button onClick={toggleSide}>
            <CarotsLeft />
          </button>
        </div>
      </li>
    </ul>
  )
}

SideBar.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      ProjectId: propTypes.string
    })
  }),
  location: propTypes.object
}

export default SideBar
