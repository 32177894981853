import * as React from "react"
// import { createMachine, assign } from "xstate"
import { useMachine } from "@xstate/react"
import { createMachine, assign } from "xstate"
// import toggleMachine from './machine.json'
const machineCon={"preserveActionOrder": "true","predictableActionArguments":"true", "id":"toggle","key":"toggle","context":{"count":0},"type":"compound","initial":"inactive","history":false,"states":{"inactive":{"id":"toggle.inactive","key":"inactive","type":"atomic","history":false,"states":{},"on":{"TOGGLE":[{"target":["#toggle.active"],"event":"TOGGLE","actions":[],"source":"#toggle.inactive","internal":false,"eventType":"TOGGLE"}]},"transitions":[{"target":["#toggle.active"],"event":"TOGGLE","actions":[],"source":"#toggle.inactive","internal":false,"eventType":"TOGGLE"}],"entry":[],"exit":[],"activities":[],"order":1,"invoke":[],"tags":[]},"active":{"id":"toggle.active","key":"active","type":"atomic","history":false,"states":{},"on":{"TOGGLE":[{"target":["#toggle.inactive"],"event":"TOGGLE","actions":[],"source":"#toggle.active","internal":false,"eventType":"TOGGLE"}]},"transitions":[{"target":["#toggle.inactive"],"event":"TOGGLE","actions":[],"source":"#toggle.active","internal":false,"eventType":"TOGGLE"}],"entry":[{"type":"xstate.assign","assignment":{}}],"exit":[],"activities":[],"order":2,"invoke":[],"tags":[]}},"on":{},"transitions":[],"entry":[],"exit":[],"activities":[],"order":-1,"invoke":[],"tags":[]}
const machine = createMachine(machineCon, machineCon.config)
console.log(JSON.stringify(machine))
const toggleMachine = createMachine({
  id: "toggle",
  initial: "inactive",
  context: {
    count: 0
  },
  states: {
    inactive: {
      on: { TOGGLE: "active" }
    },
    active: {
      entry: assign({ count: (ctx) => ctx.count + 1 }),
      on: { TOGGLE: "inactive" }
    }
  }
});

console.log(JSON.stringify(toggleMachine))
function Tryout() {

  const [state, send] = useMachine(machine)
  const active = state.matches("active");
  const { count } = state.context;

  console.log(state)
  return (
    <div className="App">
      <h1>XState React Template</h1>
      <h2>Fork this template!</h2>
      <button onClick={() => send("TOGGLE")}>
        Click me ({active ? "✅" : "❌"})
      </button>{" "}
      <code>
        Toggled <strong>{count}</strong> times
      </code>
    </div>
  );
}

export default Tryout