import React from 'react'
import propTypes from 'prop-types'
import FormInput from '../../components/Forms/FormInput'
import SubmitButton from '../../components/Forms/SubmitButton'
import useForm from '../../components/Forms/useForm'

const OrganisationSettings = () => {
  const { values, handleChange } = useForm({
    initialValues: {
      organisationName: '',
      description: '',
      time: '',
      region: ''
    }
  })

  const handleSaveSettings = async (e) => {
    e.preventDefault()
    return values
  }
  return (
    <div className='organisation-settings'>
      <div className='organisation-settings-form'>
        <form onSubmit={handleSaveSettings}>
          <div className='organisation-settings-name'>
            <h3>Name</h3>
            <FormInput
              className='input-settings-form name'
              type='text'
              placeholder='RLCM'
              name='organisationName'
              value={values.organisationName}
              handleChange={handleChange}
            />
            <div className='url-link'>
              use the following url: {values.organisationName ? ' http://localhost:8080/' + values.organisationName : 'http://localhost:8080/RLCM'}
            </div>
          </div>
          <div className='organisation-settings-description'>
            <h3>Description</h3>
            <FormInput
              type='text'
              className='input-settings-form description'
              placeholder='description'
              name='description'
              value={values.description}
              handleChange={handleChange}
            />
          </div>
          <div className='organisation-settings-time'>
            <h3>Time Zone</h3>
            <FormInput
              type='text'
              className='input-settings-form time'
              placeholder='(UTC+01:00) Amsterdam, Berlin, Rome, ...'
              name='time'
              value={values.time}
              handleChange={handleChange}
            />
          </div>
          <div className='organisation-settings-region'>
            <h3>Region</h3>
            <FormInput
              type='text'
              className='input-settings-form region'
              placeholder='West Europe'
              name='region'
              value={values.region}
              handleChange={handleChange}
            />
          </div>
          <div>
            <SubmitButton
              className='button-high' name='Save' type='submit'
            />
          </div>
        </form>
      </div>
      <div className='organistion-owner'>
        <h3>Organisation Owner</h3>
        <div className='owner'>
          Elo Bosma
        </div>
        <button className='button-high'>
          Change Owner
        </button>
      </div>
      <div className='delete'>
        <h3>Delete Organisation</h3>
        <div className='delete-explanation'>
          This will affect all contents and all members of the organisation
        </div>
        <button className='button-delete'>
          Delete
        </button>
      </div>
    </div>
  )
}

OrganisationSettings.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      ProjectId: propTypes.string,
      UserId: propTypes.string,
      OrganisationId: propTypes.string
    })
  })
}

export default OrganisationSettings
