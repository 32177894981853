import React, { useState, useCallback, useRef } from 'react'
import ReactFlow, {
	ReactFlowProvider,
	MiniMap,
	Controls
} from 'reactflow'
import 'reactflow/dist/style.css'
import { upload, download, onEdgesChange, onNodesChange, onConnect, addNode, init} from '../../flux/actions/draggableActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
const proOptions = { hideAttribution: true } // used to hide the react flow attribution
const flowKey = 'example-flow'

const getNodeId = () => `randomnode_${+new Date()}`

const Canvas = (props) => {

	const [rfInstance, setRfInstance] = useState(null)
	const reactFlowWrapper = useRef(null);
	
	const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

	const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = rfInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getNodeId(),
        type,
        position,
				sourcePosition: "right",
				targetPosition: "left",
        data: { label: `${type} node` },
      };

      props.addNode(newNode);
    },
    [rfInstance]
  );

	const onSave = useCallback(() => {
		if (rfInstance) {
			const flow = rfInstance.toObject()
			props.upload(flowKey, JSON.stringify(flow)) // currently as cookie, adapt to mongodb
		}
	}, [rfInstance])

	const onRestore = useCallback(() => {
		const restoreFlow = async () => {
			props.download(flowKey)
		}

		restoreFlow()
	}, [props])


	const onAdd = useCallback(() => {
		const newNode = {
			id: getNodeId(),
			data: { label: 'Click me' },
			sourcePosition: "right",
			targetPosition: "left",
			position: {
				x: Math.random() * 10,
				y: Math.random() * 10,
			},
		}
		props.addNode(newNode)
	}, [props.addNode])

	return (
		<ReactFlowProvider>
			<ReactFlow className="reactflow-wrapper" ref={reactFlowWrapper}
				nodes={props.nodes}
				edges={props.edges}
				onNodesChange={props.onNodesChange}
				onEdgesChange={props.onEdgesChange}
				onConnect={props.onConnect}
				onInit={setRfInstance}
				onDrop={onDrop}
				onDragOver={onDragOver}
				proOptions={proOptions}
			>
				<div className='save__controls'>
					<button onClick={onSave}>save</button>
					<button onClick={onRestore}>restore</button>
					<button onClick={onAdd}>add node</button>
				</div>
				<Controls />
				<MiniMap nodeStrokeWidth={3} zoomable pannable />
			</ReactFlow>
		</ReactFlowProvider>
	)
}

Canvas.propTypes = {
  nodes: PropTypes.array,
	edges: PropTypes.array,
	onNodesChange: PropTypes.func,
	onEdgesChange: PropTypes.func,
	onConnect: PropTypes.func,
	addNode: PropTypes.func,
	init: PropTypes.func,
	upload: PropTypes.func,
	download: PropTypes.func
}

const mapStateToProps = state => ({
	nodes: state.draggable.nodes,
	edges: state.draggable.edges,
})

export default connect(
  mapStateToProps , {onNodesChange, onEdgesChange, onConnect, addNode, init, upload, download}
)(Canvas)
