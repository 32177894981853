import React from 'react'
import SearchBar from '../TopBar/SearchBar'
import TopSettingSideBarText from './IconText/TopSettingSideBarText'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useMatchParams from '../MatchParams/useMatchParams'

const SideBar = () => {
  const useObjectId = useMatchParams
  const Ids = useObjectId()
  let OrganisationId = Ids.OrganisationId
  const ProjectIds = Ids.ProjectId
  const UserIds = Ids.UserId
  const UserId = Ids.UserIds != null ? UserIds : '6037d59f99451e0d38d73a50'
  const ProjectId = ProjectIds != null ? ProjectIds : '6037d59f99451e0d38d73a50'
  OrganisationId = OrganisationId != null ? OrganisationId : 'RLCM'

  return (
    UserIds
      ? <UserSettings ProjectId={ProjectId} UserId={UserId} OrganisationId={OrganisationId} />
      : ProjectIds
        ? <ProjectSettings ProjectId={ProjectId} UserId={UserId} OrganisationId={OrganisationId} />
        : OrganisationId
          ? <OrganisationSettings ProjectId={ProjectId} UserId={UserId} OrganisationId={OrganisationId} />
          : null
  )
}

SideBar.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      ProjectId: propTypes.string
    })
  }),
  location: propTypes.object
}

const OrganisationSettings = (props) => {
  return (
    <ul className='nav__list'>
      <li className='nav__listitem'>
        <div className='bold'>
          <Link to={'/' + props.OrganisationId + '/projects/'} className='sidebarref'>
            <TopSettingSideBarText />
          </Link>
        </div>
      </li>
      <li className='nav__listitem'>
        <hr className='hr' />
      </li>
      <li className='nav__listitem'>
        <SearchBar />
      </li>
      <li className='nav__listitem'>
        General
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/overview'} className='sidebarref'>
          Overview
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projectsettings'} className='sidebarref'>
          Project Settings
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/users'} className='sidebarref'>
          Users
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/billing'} className='sidebarref'>
          Billing
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/auditing'} className='sidebarref'>
          Auditing
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/reports'} className='sidebarref'>
          Reports
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/workflows'} className='sidebarref'>
          Workflows
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/extensions'} className='sidebarref'>
          Extensions
        </Link>
      </li>
      <li className='nav__listitem'>
        Security
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/policies'} className='sidebarref'>
          Policies
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/permissions'} className='sidebarref'>
          Permissions
        </Link>
      </li>
    </ul>
  )
}
OrganisationSettings.propTypes = {
  UserId: propTypes.string,
  OrganisationId: propTypes.string,
  ProjectId: propTypes.string
}

const UserSettings = (props) => {
  return (
    <ul className='nav__list'>
      <li className='nav__listitem'>
        <div className='bold'>
          <Link to={'/settings/users/' + props.UserId} className='sidebarref'>
            <TopSettingSideBarText />
          </Link>
        </div>
      </li>
      <li className='nav__listitem'>
        <hr className='hr' />
      </li>
      <li className='nav__listitem'>
        <SearchBar />
      </li>
      <li className='nav__listitem'>
        General
      </li>
    </ul>
  )
}

UserSettings.propTypes = {
  UserId: propTypes.string,
  OrganisationId: propTypes.string,
  ProjectId: propTypes.string
}
const ProjectSettings = (props) => {
  return (
    <ul className='nav__list'>
      <li className='nav__listitem'>
        <div className='bold'>
          <Link to={'/settings/organisations/' + props.OrganisationId + '/projects/' + props.ProjectId} className='sidebarref'>
            <TopSettingSideBarText />
          </Link>
        </div>
      </li>
      <li className='nav__listitem'>
        <hr className='hr' />
      </li>
      <li className='nav__listitem'>
        <SearchBar />
      </li>
      <li className='nav__listitem'>
        General
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projects/' + props.ProjectId + '/overview'} className='sidebarref'>
          Overview
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projects/' + props.ProjectId + '/users'} className='sidebarref'>
          Users
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projects/' + props.ProjectId + '/billing'} className='sidebarref'>
          Billing
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projects/' + props.ProjectId + '/auditing'} className='sidebarref'>
          Auditing
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projects/' + props.ProjectId + '/reports'} className='sidebarref'>
          Reports
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projects/' + props.ProjectId + '/workflows'} className='sidebarref'>
          Workflows
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projects/' + props.ProjectId + '/extensions'} className='sidebarref'>
          Extensions
        </Link>
      </li>
      <li className='nav__listitem'>
        Security
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projects/' + props.ProjectId + '/policies'} className='sidebarref'>
          Policies
        </Link>
      </li>
      <li className='nav__listitem'>
        <Link to={'/settings/organisation/' + props.OrganisationId + '/projects/' + props.ProjectId + '/permissions'} className='sidebarref'>
          Permissions
        </Link>
      </li>
    </ul>
  )
}
ProjectSettings.propTypes = {
  UserId: propTypes.string,
  OrganisationId: propTypes.string,
  ProjectId: propTypes.string
}
export default SideBar
