import React, { Component } from 'react'
import { Routes, Navigate, Route, Outlet } from 'react-router-dom'
import '../stylesheets/style.css'
import Breadcrumbs from './BreadCrumbs/BreadCrumbs.js'
import TopBar from './TopBar/TopBar'
import SideBar from './SideBar/SideBar'
import UserSettings from '../pages/Settings/UserSettings'
import OrganisationSettings from '../pages/Settings/OrganisationSettings'
import ProjectSettings from '../pages/Settings/ProjectSettings'
import SidebarSettings from './SideBar/SideBarSettings'
// import ButtonsMain from './Buttons/ButtonsMain'
import { ThemeSelectorConsumer } from '../contexts/ThemeContext'

const Layout = () => {
  return (
    <ThemeSelectorConsumer>
      {({ theme }) => (
        <div id='app1' className={'container ' + theme}>
          <div className='item-navbar'>
            <TopBar />
          </div>
          <div className='item-sidebar'>
            <SideBar />
          </div>
          <div className='item-breadcrumb'>
            <Breadcrumbs />
          </div>
          <div className='item-buttons'>
            {/* <ButtonsMain /> */}
          </div>
          <div className='item-main-settings'>
            <div className='item-main-settings-sidebar'>
              <SidebarSettings />
            </div>
            <div className='item-main-settings-view'>
              <Outlet />
            </div>
          </div>
          <div className='item-footer'>
            @ RLCM
          </div>
        </div>
      )}
    </ThemeSelectorConsumer>
  )
}

class WireFrameSettings extends Component {
  // constructor (props) {
  //   super(props)
  //   this.state = { showPopup: false }
  //   this.setPopup = this.setPopup.bind(this)
  // }

  // set state to 'not show pop up'. Rule 41 en 47 nog even bekijken
  // setPopup () {
  //   this.setState({ showPopup: !this.state.showPopup })
  // }

  render () {
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path='/users/:UserId' element={<UserSettings />} />
          <Route path='/organisations/:OrganisationId/projects/:ProjectId' element={<ProjectSettings />} />
          <Route path='/organisations/:OrganisationId/projects/' to='/settings/organisations/:OrganisationId' />
          <Route path='/organisations/:OrganisationId' element={<OrganisationSettings />} />
          <Route path='*' element={<Navigate path='*' to='/home' />} />
        </Route>
      </Routes>
    )
  }
}

export default WireFrameSettings
