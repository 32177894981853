import React from 'react'
import propTypes from 'prop-types'

const SubmitButton = (props) => {
  return (
    <button className={props.className ? props.className : 'btn btn--CTA'} type={props.type} onSubmit={props.handleSubmit}>
      {props.name}
    </button>
  )
}

SubmitButton.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  handleSubmit: propTypes.func,
  className: propTypes.string
}
export default SubmitButton
