import React, { Component } from 'react'
import '../../stylesheets/style.css'
import PropTypes from 'prop-types'
import OrganisationMenuDropDown from './OrganisationMenu'
import CarotDown from '../Icons/CarotDown'
import ClassMatchParams from '../MatchParams/ClassMatchParams'
class OrganisationButton extends Component {
  constructor (props) {
    super(props)
    this.myRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleClickInside = this.handleClickInside.bind(this)
    this.state = {
      open: false
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside (e) {
    if (!this.myRef.current.contains(e.target) && this.state.open) {
      this.setState({ open: false })
    }
  };

  handleClickInside () {
    this.setState({ open: !this.state.open })
  }

  render () {
    return (
      <div className='NavItemOrganisation'>
        <div ref={this.myRef}>
          <button
            className='OrganisationMenuButton'
            onClick={this.handleClickInside}
          >
            <ClassMatchParams OrganisationId />
            <CarotDown />
          </button>
          {this.state.open && (
            <OrganisationMenuDropDown />
          )}
        </div>
      </div>
    )
  }
}

OrganisationButton.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      OrganisationId: PropTypes.string
    })
  })
}

export default OrganisationButton
